export default {
    graphURL: 'https://app-developer.tradesafe.dev/api/graphql',
    sessionTimeout: 600000,
    contactUsFormURL: '',
    authServer: 'https://auth.tradesafe.co.za',
    authServerLogout: 'https://auth.tradesafe.co.za/logout',
    oauthClientID: '915e8eb9-5e9f-4b70-9461-d29c10987d66',
    appDomain: 'https://app-developer.tradesafe.dev',
    ecentricEndpoint: 'sandbox.ecentric.co.za',
    pingProfilePage: '',
}
